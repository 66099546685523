<template>
  <div id="knowledgeTree" data-id="s0">
    <input type="text" readonly="readonly" autocomplete="off" :value="checkedTitles" @click.stop="handleTreeClick"  placeholder="请选择" class="el-input__inner">
    <span class="el-input__suffix">
      <span class="el-input__suffix-inner">
        <i :class='arrowClass'></i>
      </span>
    </span>

    <my-el-tree
        :class="treeClass"
        :data="allNodes"
        :show-checkbox="true"
        node-key="id"
        :check-strictly="true"
        :default-checked-keys="checkedIds"
        :props="props"
         @check="handleCheckChange"
        ref="elTree"
    >
    </my-el-tree>
  </div>
</template>

<script>
import MyElTree from "../views/question/tree/src/tree"
export default {
  data(){
    return{
      name: "Tree",
      props: {
        children: 'children',
        label: 'title'
      },
      treeShow:false,
      arrowClass:'el-select__caret el-input__icon el-icon-arrow-up transform-180',
      treeClass:'select-tree hide',
      allNodes:[],
      checkedNodes:[],
      checkedIds:[],
      checkedTitles:''
    }
  },
  components:{MyElTree},
  methods: {
    handleTreeClick(){
      this.treeShow = !this.treeShow;
      if(this.treeShow){
        this.arrowClass = 'el-select__caret el-input__icon el-icon-arrow-up'
        this.treeClass  = 'select-tree show'
      }
      else{
        this.arrowClass = 'el-select__caret el-input__icon el-icon-arrow-up  transform-180'
        this.treeClass  = 'select-tree hide'
      }
    },
    hideTree(){
      if(this.treeShow){
        this.treeShow=false;
        this.arrowClass = 'el-select__caret el-input__icon el-icon-arrow-up  transform-180'
        this.treeClass  = 'select-tree hide'
      }
    },
    handleCheckChange(data,checkData) {
      this.checkedNodes = checkData.checkedNodes;
      this.checkedTitles = this.getCheckedTitles();
    },
    initData:function (allNodes, checkedNodes){
      this.allNodes = allNodes;

      if(checkedNodes != null){
        this.checkedNodes = checkedNodes;
        this.checkedIds = this.getCheckedIds();
        this.checkedTitles = this.getCheckedTitles();
      }
    },
    getCheckedIds(){
      const ids = [];
      for(let i=0; i<this.checkedNodes.length; i++){
          ids.push(this.checkedNodes[i].id);
      }
      return ids;
    },
    getCheckedTitles(){
      let titles = '';
      for(let i=0; i<this.checkedNodes.length; i++){
        titles += this.checkedNodes[i].title + ",";
      }
      return titles;
    },
    getCheckedNodes(){
      return this.checkedNodes;
    }
  }
}
</script>

<style scoped>
input{
  cursor: pointer;
}
div{
  margin: 0px;
  padding: 0px;
}
.select-tree{
  position: absolute;
  border: 1px solid #DCDFE6;
  cursor: pointer;
  border-radius: 4px;
  z-index: 1000;
  margin-top:5px;
  width: 100%;
  height: 300px;
  overflow: auto;
}
.transform-180{
  transform:rotate(180deg);
}

/*为下拉框展开时添加名称为slide-down的关键帧动画*/
@-webkit-keyframes slide-down{
  0%{transform: scale(1,0);}
  100%{transform: scale(1,1);}
}
.show {
  transition: max-height .1s ease-in;
  transform-origin: 50% 0;
  animation: slide-down .1s ease-in;
  -webkit-animation: slide-down .1s ease-in;
}

.hide {
  display: none;
  transition: max-height .1s ease-out;
}

</style>
