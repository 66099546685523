<template>
  <el-form-item label="选择课程" :required="true">
    <select-tree-multiple ref="selectTree" ></select-tree-multiple>
  </el-form-item>
</template>

<script>
import {getLocalProjectId, UrlEnum} from "../../public/js/common-vue";
import SelectTreeMultiple from "@/components/SelectTreeMultiple";
import {SESSON_KEY_INTEGRAL_SELCOURSE} from "../../public/js/sesson-key-vue";

export default {
  name: "CourseFormItemMultiple",
  data(){
    return {
      courses:[],
    }
  },
  components:{SelectTreeMultiple},
  methods:{
    //获取session
    getSessionCourses() {
      let courses = sessionStorage.getItem(SESSON_KEY_INTEGRAL_SELCOURSE);
      if(courses != null){
        courses = JSON.parse(courses);
      }
      return courses;
    },
    //保存session
    setSessionCourse(courses) {
      sessionStorage.setItem(SESSON_KEY_INTEGRAL_SELCOURSE,JSON.stringify(courses))
    },
    getCheckedIds(){
      return this.$refs.selectTree.getCheckedIds();
    },
    getCourseIds(){
      const courseIds = [];
      const checkedNodes = this.$refs.selectTree.getCheckedNodes();
      for(let i=0;i<checkedNodes.length;i++){
        courseIds[i] = checkedNodes[i].id;
      }
      return courseIds;
    },
    getCheckedCourses(){
      const checkedNodes = this.$refs.selectTree.getCheckedNodes();
      const courses = [];
      for(let i=0;i<checkedNodes.length;i++){
        const course = {id: checkedNodes[i].id, name: checkedNodes[i].title};
        courses[i] = course;
      }
      return courses;
    },
    initData(courses,categoryIds) {
      if(courses != null){
        this.setSessionCourse(courses);
      }
      let url = UrlEnum.COURSE_TREE_DROPDOWN + '?projectId=' + getLocalProjectId() + '&page=1&size=100';
      if(categoryIds != null){
        url += "&categoryIds="+categoryIds;
      }
      this.$http({
        method: "get",
        url: url,
        data: '',
      })
          .then((res) => {
            this.courses = res.data.list;
            // console.log(JSON.stringify(this.courses));
            // console.log("===========")
            let checkedNodes = [];
            const sessionCourses = this.getSessionCourses();
            if(sessionCourses != null && sessionCourses != ""){
              //排除不在集合中的课程
              // console.log(JSON.stringify(sessionCourses));
              let newSessionCourses = [];
              for(let i=sessionCourses.length-1;i>=0;i--){
                for(let j=this.courses.length-1; j>=0; j--){
                  let children = this.courses[j].children;
                  if(children != null){
                    for(let k = children.length-1; k>=0;k--){
                      if(sessionCourses[i].id == children[k].id){
                        newSessionCourses.push(sessionCourses[i]);
                      }
                    }
                  }
                  if(sessionCourses[i].id == this.courses[j].id){
                    newSessionCourses.push(sessionCourses[i]);
                  }
                }
              }
              //设置选中值
              // console.log(JSON.stringify(newSessionCourses));
              for(let i=0;i<newSessionCourses.length;i++){
                let sessionCourse = newSessionCourses[i];
                checkedNodes[i] = {id:newSessionCourses[i].id,title:newSessionCourses[i].name}
              }
            }
            this.$refs.selectTree.initData(this.courses,checkedNodes);
            console.log("checkedNodes:");
            console.log(checkedNodes);
          })
          .catch((res) => {
            // this.loading=false;
            if(res.response != null){
              const data = res.response.data;
              this.showMsgError(data.message);
            }
          });
    }
  }
}
</script>

<style scoped>

</style>
